import { useEffect, useMemo, useState } from "react";
import { getRequestData } from "api/api";
import { endpoints } from "api/endpoints";
import GlobalModal from "components/globalModal"
import { SingleInvoiceRow } from "./singleInvoiceRow";
import { Pagination } from "antd";
import DynamicRows from "./dynamicRows";

export const InvoiceHistory = ({ isOpen, onClose, partnerId, role, partnerName, partnerAddress }) => {
  const [invoices, setInvoices] = useState([]);
  const [activeInvoice, setActiveInvoice] = useState(null);

  useEffect(() => {
    (async () => {
      const { data } = await getRequestData(endpoints.getWeeklyClientInvoices(partnerId), role);
      setInvoices(data);
    })();
  }, [partnerId, role])

  const handlePaginationChange = (page) => {
    (async () => {
      const { data } = await getRequestData(endpoints.getWeeklyClientInvoices(partnerId, page), role);
      setInvoices(data);
    })();
  };

  const storeInvoices = useMemo(() => {
    return invoices?.results?.map((invoice) => {
      return (
        <SingleInvoiceRow
          key={invoice.invoice_id}
          invoice={invoice}
          onEditClick={() => setActiveInvoice(invoice)}
        />
      )
    })
  }, [invoices]);

  return (
    <GlobalModal
      open={isOpen}
      width={1044}
      title='Invoice History (Weekly)'
      onClose={onClose}
      partnerName={partnerName}
      partnerAddress={partnerAddress}
    >
      <div className="flex flex-col p-[30px] pt-6 h-full">
        <div className="flex w-full pb-4 border-b border-color-divider">
          <p className='w-[25%] text-14 text-lightBlue'>Date</p>
          <p className='w-[14%] text-14 text-lightBlue'>Active Clients</p>
          <p className='w-[15%] text-14 text-lightBlue'>Early Departures</p>
          <p className='w-[15%] text-14 text-lightBlue'>Invoice Total</p>
          <p className='w-[11%] text-14 text-lightBlue'>Status</p>
          <p className="w-[20%] text-14 text-lightBlue text-right">Actions</p>
        </div>
        {activeInvoice ?
          <div className="flex flex-col">
            <SingleInvoiceRow
              isInEditMode
              invoice={activeInvoice}
            />
            <DynamicRows
              invoiceId={activeInvoice.invoice_id}
              onCancel={() => setActiveInvoice(null)}
            />
          </div> : storeInvoices}
        {invoices.pageCount > 1 && !activeInvoice &&
          <Pagination
            className='self-center'
            onChange={page => handlePaginationChange(page)}
            pageCount={invoices.pageCount}
            pageSize={invoices.pageSize}
            total={invoices.count}
          />}
      </div>
    </GlobalModal>
  )
}