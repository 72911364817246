import React, { useEffect, useState, useCallback } from 'react';
import { Input } from 'antd';
import { SelectOption, IconPlus, IconSearch, OrangeButton, IconDownload, IconFilter, IconSmallArrow, SmallButton, IconFormMinus, IconFormPlus, CustomTable } from 'components';
import { COLORS } from 'utils/colors';
import dayjs from 'dayjs';
import DefaultAvatar from 'assets/images/default-avatar.png';
import { endpoints } from 'api/endpoints';
import classNames from 'classnames';
import { getRequestData } from 'api/api';
import { useNavigate } from 'react-router-dom';
import { CLIENT_STATUS, DATE_FORMAT, ROLE_TYPES } from 'api/constants';

export const ManagerClients = () => {
  const navigate = useNavigate();
  const [clientsList, setClientsList] = useState();
  const [selectedStatus, setSelectedStatus] = useState(null);

  const columns = [
    {
      title: 'PF25 Client',
      dataIndex: 'client',
      width: 300,
      render: (_, record) => (
        <div className='flex items-center gap-x-1'>
          <img className='w-[30px] h-[30px] rounded-full' src={record.clientPhoto ? record.clientPhoto : DefaultAvatar} alt='client' />
          <p className='text-14 ml-1'>{record.client}</p>
          {record.isHaveNotCompletedTask ? <IconFormMinus className='min-w-4 min-h-4' /> : <IconFormPlus className='min-w-4 min-h-4' />}
        </div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 120,
      render: (status, record) => {
        const statusAbbr = status.slice(0, 1).toUpperCase();
        return (
          <div className='flex items-center gap-x-3'>
            <p className='w-6 h-6 rounded-full bg-white shadow-block-shadow flex justify-center items-center'>
              <span className={classNames('uppercase text-xl leading-5 font-medium',
                { 'text-blue': statusAbbr === 'N' || statusAbbr === 'I' },
                { 'text-green': statusAbbr === 'C' },
                { 'text-red': statusAbbr === 'R' },
              )}>
                {status.slice(0, 1)}
              </span>
            </p>
            <p className='text-14 uppercase font-medium'>W{record.week ? record.week : 0}</p>
          </div>
        )
      }
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      width: 120,
      showSorterTooltip: {
        target: 'sorter-icon',
      },
      render: (text) => dayjs(text).format(DATE_FORMAT),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      width: 120,
      showSorterTooltip: {
        target: 'sorter-icon',
      },
      render: (text) => dayjs(text).format(DATE_FORMAT),
    },
    {
      title: 'Action',
      width: 100,
      dataIndex: 'action',
      className: 'action',
      render: (_, record) => <SmallButton onClick={() => navigate(`/manager/client/${record.userID}`)} label='Details' />,
    },
  ];

  const fetchClients = useCallback(async (params) => {
    const { data } = await getRequestData(endpoints.getClients(params), ROLE_TYPES.slh_manager);
    if (data) {
      const clientsData = {
        currentPage: data.currentPage,
        pageCount: data.pageCount,
        pageSize: data.pageSize,
        clients: data.results.map(client => ({
          client: `${client.firstname} ${client.lastname}`,
          clientID: client.client_id,
          clientPhoto: client.avatar,
          status: client.status,
          partner: client.partner,
          partnerID: client.partner_id,
          startDate: client.start_date,
          endDate: client.end_date,
          userID: client.user_id,
          week: client.week,
          isHaveNotCompletedTask: client.tasks.some(task => task.completed === false),
        }))
      };
      setClientsList(clientsData);
    }
  }, [])

  useEffect(() => {
    fetchClients({ page: 1, pageSize: 10 })
  }, [fetchClients])

  const handlePaginationChange = (page) => {
    const filters = { page };

    if (selectedStatus) {
      filters.status = selectedStatus;
    }

    fetchClients(filters);
  };

  const handleStatusSelectChange = (status) => {
    setSelectedStatus(status);
    fetchClients({status});
  };

  return (
    <div className="flex flex-col w-full gap-y-6">
      <div className='flex justify-between w-full'>
        <p className='nunito-title'>PF25 Clients</p>
        <div className='flex items-center gap-x-6'>
          <Input
            className='search w-[300px] text-lightBlue text-16 rounded-ten bg-light'
            type='text'
            placeholder="Search"
            addonBefore={<IconSearch color={COLORS.lightBlue} />}
          // onSearch={onSearch}
          />
          <OrangeButton onClick={() => navigate('/manager/create-client')} label='Add new client' icon={<IconPlus />} />
          <IconDownload />
        </div>
      </div>
      {clientsList && <><div className='flex items-center gap-x-3'>
        <div className='flex items-center gap-x-1 mr-3'>
          <IconFilter />
          <p className='text-16'>Filter</p>
        </div>
        <SelectOption
          onSelect={(value) => handleStatusSelectChange(value)}
          dropdownStyle={{ padding: '16px', background: 'white' }}
          required
          suffixIcon={<IconSmallArrow color={COLORS.black} className='rotate-[-90deg] w-3 h-3' />}
          className='h-[40px] text-base font-medium base-block rounded-lg'
          placeholder='Status'
          popupClassName='min-w-[180px]'
          dropdownRender={(originalNode) => originalNode}
          options={[
            { label: <p className='text-14 px-4 py-1.5 rounded-md'>In Progress</p>, value: CLIENT_STATUS.in_progress },
            { label: <p className='text-14 px-4 py-1.5 rounded-md'>Completed</p>, value: CLIENT_STATUS.completed },
            { label: <p className='text-14 px-4 py-1.5 rounded-md'>Relapsed</p>, value: CLIENT_STATUS.failed },
            { label: <p className='text-14 px-4 py-1.5 rounded-md'>Not Started</p>, value: CLIENT_STATUS.not_started }
          ]}
        />
      </div>
        <CustomTable
          columns={columns}
          data={clientsList.clients}
          pageCount={clientsList.pageCount}
          current={clientsList.currentPage}
          pageSize={clientsList.pageSize}
          onChange={handlePaginationChange}
          rowKey={record => record.userID}
        />
      </>}
    </div>
  );
}

