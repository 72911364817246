import GlobalModal from "components/globalModal"
import DefaultAvatar from 'assets/images/default-avatar.png';
import { DatePicker, Form, Input } from "antd";
import { DATE_FORMAT, ROLE_TYPES } from "api/constants";
import { COLORS } from "utils/colors";
import { IconCalendar } from "components/icons/Calendar";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { IconSmallArrow, SelectOption } from "components";
import { getRequestData, terminateClient } from "api/api";
import { endpoints } from "api/endpoints";
import classNames from "classnames";

export const Terminate = ({ isOpen, onClose, userDetails, onSuccess, isAdmin }) => {
  const [terminationReasons, setTerminationReasons] = useState();
  const [formData, setFormData] = useState({
    partner_id: userDetails.client.partner_id,
    reason: '',
    date: '',
    details: ''
  });

  const isTerminated = useMemo(() => Boolean(userDetails.client.termination_date), [userDetails.client.termination_date])
  const domain = useMemo(() => isAdmin ? ROLE_TYPES.admin : ROLE_TYPES.slh_manager, [isAdmin]);

  useEffect(() => {
    (async () => {
      const { data } = await getRequestData(endpoints.getTerminationReasons, domain);

      if (data) {
        const reasons = data.map((i) => {
          return { label: i.reason, value: i.reason }
        })
        setTerminationReasons(reasons);
      }
    })();
  }, [domain]);


  const handleTerminationDateChange = (date) => {
    const terminationDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ')
    setFormData({
      ...formData,
      date: terminationDate,
    });
  }

  const handleTerminationReasonSelect = (value) => {
    setFormData({
      ...formData,
      reason: value,
    });
  }

  const handleTextAreaChange = (e) => {
    setFormData({
      ...formData,
      details: e.target.value,
    });

  }
  const handleFormSubmit = () => {
    terminateClient(userDetails.profile.user_id, formData, domain, () => onSuccess())

  }

  return (
    <GlobalModal
      open={isOpen}
      title='Terminate'
      onClose={onClose}
      titleLeft
    >
      <Form
        name="terminate_form"
        className="flex flex-col p-[30px]"
        onFinish={handleFormSubmit}
      >
        <div className="flex justify-between">
          <div className='flex'>
            <img className='w-12 h-12 rounded-xl' src={userDetails.profile.avatar ? userDetails.profile.avatar : DefaultAvatar} alt='profile' />
            <div className='flex flex-col ml-3'>
              <b className='text-black text-base nunito'>{userDetails.profile.firstname} {userDetails.profile.lastname}</b>
              <p className='text-lightBlue text-14 font-normal mt-0.5'>{userDetails.client.name}</p>
            </div>
          </div>
          <div className='flex flex-col gap-y-1 w-full max-w-[200px]'>
            <p className='text-14 font-medium'>Termination Date</p>
            <DatePicker
              onChange={(_, date) => handleTerminationDateChange(date)}
              format={DATE_FORMAT}
              placeholder='Pick Date'
              className={classNames('h-[44px] text-base !text-black font-medium base-block rounded-lg',
                { '!text-black !bg-white !shadow-block-shadow': isTerminated })}
              name='date-of-born'
              defaultValue={isTerminated ? dayjs(userDetails.client.termination_date) : null}
              suffixIcon={!isTerminated && <IconCalendar color={COLORS.blue} />}
              disabled={isTerminated}
            />
          </div>
        </div>
        <div className='flex flex-col w-full gap-y-1 mt-6'>
          <p className='text-14 font-medium'>Termination Reason</p>
          <SelectOption
            onSelect={(value) => handleTerminationReasonSelect(value)}
            dropdownStyle={{ padding: '16px', background: 'white' }}
            disabled={isTerminated}
            suffixIcon={<IconSmallArrow color={COLORS.black} className='rotate-[-90deg] w-3 h-3' />}
            className={classNames('h-[44px] text-base font-medium base-block rounded-lg',
              { '!text-black !bg-white !shadow-block-shadow': isTerminated }
            )}
            placeholder={isTerminated ? userDetails.client.termination_reason : 'Choose Home'}
            dropdownRender={(originalNode) => originalNode}
            optionRender={(option) => (
              <div className='flex items-center px-2 py-1 gap-x-1 text-black rounded-md capitalize duration-150 hover:bg-orange'>
                <p className='font-14'>{option.label}</p>
              </div>
            )}
            options={terminationReasons}
          />
        </div>
        <div className='flex flex-col gap-y-1 w-full mt-6'>
          <p className='text-14 font-medium'>Additional Comments</p>
          <Input.TextArea
            className='!resize-none font-poppins text-14 p-3 pl-4 border border-color-divider rounded-ten max-h-[200px]'
            autoSize={false}
            rows={5}
            placeholder="Comment"
            onChange={handleTextAreaChange}
            defaultValue={userDetails.client.termination_details}
            readOnly={isTerminated}
          />
        </div>
        {!isTerminated && <button
          type='submit'
          className='px-6 py-2.5 bg-transparent border border-red rounded-[10px] mt-6 self-center text-red text-16 font-medium duration-150 hover:border-orange hover:text-orange'
        >
          Terminate
        </button>}
      </Form>
    </GlobalModal>
  )
}