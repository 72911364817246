import { IconEye, IconPen, SmallButton } from "components";
import dayjs from "dayjs";
import { formatPriceWithComma } from 'utils/helpers';
import { DATE_FORMAT, INVOICE_STATUS, ROLE_TYPES } from "api/constants";
import { COLORS } from "utils/colors";
import { getRequestData, submitInvoiceByManager } from "api/api";
import { useMemo, useState } from "react";
import { endpoints } from "api/endpoints";
import { useAuth } from "context/authContext";

export const SingleInvoiceRow = ({ invoice, onEditClick, isInEditMode }) => {
  const { role } = useAuth();
  const [invoiceData, setInvoiceData] = useState(invoice);

  const clickHandler = (invoice_id) => {
    submitInvoiceByManager(invoice_id, () => {
      getRequestData(endpoints.getInvoice(invoice_id), ROLE_TYPES.slh_manager, (data) => {
        setInvoiceData(data);
      })
    })
  }

  const storeButton = useMemo(() => {
    if (invoiceData.url || role === ROLE_TYPES.admin) {
      return (
        <a href={invoiceData.url} target='_blank' rel="noreferrer" download={`invoice-${invoiceData.invoice_id}`}>
          <SmallButton
            btnClassName='h-10'
            disabled={role === ROLE_TYPES.admin && invoiceData.status === INVOICE_STATUS.pending}
            label='Download' />
        </a>
      )
    }

    return (
      <div className="flex items-center justify-end gap-x-2">
        {isInEditMode && <IconEye color={COLORS.blue} className='bg-white p-2 w-10 h-10 shadow-block-shadow rounded-lg cursor-pointer' />}
        {!isInEditMode && <IconPen onClick={onEditClick} color={COLORS.blue} className='bg-white p-2 w-10 h-10 shadow-block-shadow rounded-lg cursor-pointer' />}
        {!isInEditMode && <SmallButton
          onClick={() => clickHandler(invoiceData.invoice_id)}
          btnClassName='!bg-yellow !text-orange !h-10'
          label='Submit' />}
      </div>
    )
  }, [invoiceData.url, invoiceData.invoice_id, invoiceData.status, role, isInEditMode, onEditClick]);

  return (
    <div className="flex items-center w-full border-b border-color-divider py-4">
      <p className='w-[25%] text-14 font-medium'>{`${dayjs(invoiceData.week_start).format(DATE_FORMAT)} - ${dayjs(invoiceData.week_end).format(DATE_FORMAT)}`}</p>
      <p className='w-[14%] text-14 font-medium'>{invoiceData.active_clients}</p>
      <p className='w-[15%] text-14 font-medium'>{invoiceData.relapsed}</p>
      <p className='w-[15%] text-14 font-medium'>${formatPriceWithComma(invoiceData.total)}</p>
      <p className={`w-[11%] text-14 font-medium ${invoiceData.status === INVOICE_STATUS.pending ? 'text-orange' : invoiceData.status === INVOICE_STATUS.paid ? 'text-green' : 'text-black'}`}>
        {INVOICE_STATUS[invoiceData.status.toLowerCase()]}
      </p>
      <div className="flex items-center justify-end w-[20%]">
        {storeButton}
      </div>
    </div>
  );
}
