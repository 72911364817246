import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import RootLayout from 'routes/RootLayout.jsx';
import { AuthProvider } from 'context/authContext';
import { ConfigProvider } from 'antd';
import {
  LoginPage,
  AdminDashboard,
  CreateClientByAdmin,
  AdminClients,
  AdminPartners,
  CreatePartner,
  AdminFinances,
  ClientLoginPage,
  ManagerDashboard,
  ManagerClients,
  CreateClientByManager,
  ManagerFinances,
  AdminSingleClient,
  ManagerSingleClient,
  ClientDashboard,
  ClientProfile,
  EditPartner,
  WeeklyForms,
  WeeklyFormsAdmin,
  EditClient,
  EditClientByManager,
  WeeklyFormsManager
} from 'pages';
import ProtectedManagerRoute from 'routes/ProtectedManagerRoute';
import ProtectedAdminRoute from 'routes/ProtectedAdminRoute';
import { UserProvider } from 'context/userContext';
import { IsAuthenticated } from 'components';
import ProtectedClientRoute from 'routes/ProtectedClientRoute';
import RootLayoutClient from 'routes/RootLayoutClient';

function App() {
  return (
    <ConfigProvider theme={{ hashed: false }}>
      <Router>
        <AuthProvider>
          <UserProvider>
            <Routes>
              <Route
                path="/"
                element={<LoginPage />}
              />
              <Route
                path="/login"
                element={<ClientLoginPage />}
              />
              <Route path='admin'
                element={
                  <ProtectedAdminRoute>
                    <RootLayout />
                  </ProtectedAdminRoute>
                }
              >
                <Route
                  path="dashboard"
                  element={<AdminDashboard />}
                />
                <Route
                  path="create-client"
                  element={<CreateClientByAdmin />}
                />
                <Route
                  path="clients"
                  element={<AdminClients />}
                />
                <Route
                  path="client/:user_id"
                  element={<AdminSingleClient />}
                />
                <Route
                  path="edit-client/:user_id"
                  element={<EditClient />}
                />
                <Route
                  path="client/:user_id/weekly-forms/:week_id"
                  element={<WeeklyFormsAdmin />}
                />
                <Route
                  path="create-partner"
                  element={<CreatePartner />}
                />
                <Route
                  path="partners"
                  element={<AdminPartners />}
                />
                <Route
                  path="partner/:partner_id"
                  element={<EditPartner />}
                />
                <Route
                  path="finances"
                  element={<AdminFinances />}
                />
                <Route path='*' element={<IsAuthenticated />} />
              </Route>
              <Route
                path='manager'
                element={
                  <ProtectedManagerRoute>
                    <RootLayout />
                  </ProtectedManagerRoute>
                }
              >
                <Route
                  path="dashboard"
                  element={<ManagerDashboard />}
                />
                <Route
                  path="clients"
                  element={<ManagerClients />}
                />
                <Route
                  path="create-client"
                  element={<CreateClientByManager />}
                />
                <Route
                  path="edit-client/:user_id"
                  element={<EditClientByManager />}
                />
                <Route
                  path="finances"
                  element={<ManagerFinances />}
                />
                <Route
                  path="client/:user_id"
                  element={<ManagerSingleClient />}
                />
                <Route
                  path="client/:user_id/weekly-forms/:week_id"
                  element={<WeeklyFormsManager />}
                />
                <Route path='*' element={<IsAuthenticated />} />
              </Route>
              <Route path='client'
                element={
                  <ProtectedClientRoute>
                    <RootLayoutClient />
                  </ProtectedClientRoute>
                }
              >
                <Route
                  path="dashboard"
                  element={<ClientDashboard />}
                />
                <Route
                  path="me"
                  element={<ClientProfile />}
                />
                <Route
                  path="weekly-forms/:week_index"
                  element={<WeeklyForms />}
                />
                <Route path='*' element={<IsAuthenticated />} />
              </Route>
              <Route path='*' element={<IsAuthenticated />} />
            </Routes>
          </UserProvider>
        </AuthProvider>
      </Router>
    </ConfigProvider>
  );
}

export default App;
