import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';
import { FormInput, IconSmallArrow, OrangeButton, IconPen, IconPaperclip, SmallButton, IconEye, IconClipboardType, Terminate as TerminateModal } from 'components';
import classNames from 'classnames';
import { COLORS } from 'utils/colors';
import DefaultAvatar from 'assets/images/default-avatar.png';
import { getRequestData } from 'api/api';
import { endpoints } from 'api/endpoints';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDate, formatPriceWithComma, getIdFromPath } from 'utils/helpers';
import { GENDER, ROLE_TYPES, CLIENT_STATUS } from 'api/constants';

export const ManagerSingleClient = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [isTerminateOpen, setIsTerminateOpen] = useState(false);
  const userID = getIdFromPath(location.pathname);

  const getUser = async (userID) => {
    try {
      const { data } = await getRequestData(endpoints.getUser(userID), ROLE_TYPES.slh_manager);
      return data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };

  useEffect(() => {
    (async () => {
      const data = await getUser(userID);
      if (data) {
        setUserData(data);
      }
    })();
  }, [location.pathname, userID])

  const updateUserData = async () => {
    const data = await getUser(userID);
    if (data) {
      setUserData(data);
      setIsTerminateOpen(false);
    }
  }

  return userData && (
    <div className='flex flex-col w-full gap-6' >
      <div className='flex justify-between w-full'>
        <div className='flex items-center gap-x-4'>
          <div onClick={() => navigate('/manager/clients')} className='w-10 h-10 bg-white rounded-lg shadow-block-shadow flex items-center justify-center cursor-pointer'>
            <IconSmallArrow className='w-2 h-4' />
          </div>
          <p className='nunito-title'>{userData.profile.firstname} {userData.profile.lastname}</p>
        </div>
        <OrangeButton onClick={() => navigate(`/manager/edit-client/${userID}`)} label='Edit' icon={<IconPen />} />
      </div>
      <div className='flex gap-6 items-start'>
        <div className='flex flex-col gap-[18px] base-block pt-[30px] w-[442px]'>
          <p className='nunito-title px-[30px] pb-[11px]'>PF25 Client Information</p>
          <div className={classNames('relative w-full flex flex-col pt-6 pb-[30px] gap-y-[18px] border-t border-color-lightBlue px-[30px] self-center items-center',
            'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
          )}>
            <img className='self-center w-[190x] h-[190px] rounded-xl' src={userData.profile.avatar ? userData.profile.avatar : DefaultAvatar} alt='profile' />
            <div className='flex gap-x-[18px]'>
              <FormInput
                label="First Name"
                readOnly
                value={userData.profile.firstname}
              />
              <FormInput
                label="Last Name"
                value={userData.profile.lastname}
                readOnly
              />
            </div>
            <FormInput
              label="Date of Birth"
              value={formatDate(userData.profile.birthdate)}
              className='w-full'
              readOnly
            />
            <FormInput
              label="Email"
              value={userData.profile.email}
              className='w-full'
              readOnly
            />
            <FormInput
              className='w-full'
              type='number'
              label="Phone"
              prefix='+1'
              value={userData.profile.phone}
              readOnly
            />
            <div className='flex justify-between w-full p-4 rounded-lg shadow-block-shadow'>
              <div className='flex items-center gap-x-1'>
                <IconPaperclip color={COLORS.black} />
                <p className='text-16'>Attached Document</p>
              </div>
              {userData.client?.documents[0]?.url && <SmallButton icon={<IconEye color={COLORS.blue} />} label='View' href={userData.client?.documents[0].url} target="_blank" />}
            </div>
            <FormInput
              label="Start Date"
              value={formatDate(userData.client?.start_date)}
              className='w-full'
              readOnly
            />
            {userData.profile.gender && <div className='flex flex-col gap-y-1 self-start'>
              <p className='text-14 font-medium'>Gender*</p>
              <Radio className='px-4 py-2.5 bg-white border border-color-divider rounded-xl ' value={userData.profile.gender} checked>{GENDER[userData.profile.gender]}</Radio>
            </div>}
            <FormInput
              className='w-full'
              label="Identify As"
              value={userData.profile.identifyas}
              readOnly
            />
          </div>
        </div>
        <div className='flex flex-col base-block pt-[30px] flex-1'>
          <div className='flex justify-between px-[30px] pb-[17px]'>
            <p className='nunito-title'>Progress</p>
          </div>
          <div className={classNames('relative w-full flex flex-col pt-6 pb-[30px] border-t border-color-lightBlue px-[30px] self-center items-center',
            'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
          )}>
            <div className='flex flex-col gap-y-3 w-full'>
              <div className='flex justify-between'>
                <p className='text-14 text-lightBlue whitespace-nowrap'>Status</p>
                <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                <div className='flex items-center gap-x-3'>
                  {userData.client?.status === CLIENT_STATUS.failed && <IconEye color={COLORS.blue} onClick={() => setIsTerminateOpen(true)} />}
                  <p className='w-6 h-6 rounded-full bg-white shadow-block-shadow flex justify-center items-center'>
                    <span className={classNames('uppercase text-xl leading-5 font-medium',
                      { 'text-blue': userData.client?.status === CLIENT_STATUS.not_started || userData.client?.status === CLIENT_STATUS.in_progress },
                      { 'text-green': userData.client?.status === CLIENT_STATUS.completed },
                      { 'text-red': userData.client?.status === CLIENT_STATUS.failed },
                    )}>
                      {userData.client?.status.slice(0, 1)}
                    </span>
                  </p>
                  <p className='text-14 uppercase font-medium'>W{userData.client?.week}</p>
                </div>
              </div>
              <div className='flex justify-between'>
                <p className='text-14 text-lightBlue whitespace-nowrap'>Onboarding Docs</p>
                <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                <p className='text-14 line-clamp-1 max-w-[unset] text-green text-normal'>{userData.client?.onboarded ? 'Completed' : 'To complete'}</p>
              </div>
              <div className='flex justify-between'>
                <p className='text-14 text-lightBlue whitespace-nowrap'>Program Start</p>
                <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                <p className='text-14 line-clamp-1 max-w-[unset] text-green text-normal'>{userData.client?.approved ? 'Approved' : 'Denied'}</p>
              </div>
              <div className='flex justify-between'>
                <p className='text-14 text-lightBlue whitespace-nowrap'>Scholarship Start</p>
                <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                <p className='text-14 line-clamp-1 max-w-[unset]'>{`$${formatPriceWithComma(userData.client?.scolarship.start)}`}</p>
              </div>
              <div className='flex justify-between'>
                <p className='text-14 text-lightBlue whitespace-nowrap'>Scholarship Remaining</p>
                <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                <p className='text-14 line-clamp-1 max-w-[unset]'>{`$${formatPriceWithComma(userData.client?.scolarship.remaining)}`}</p>
              </div>
              <div className='flex justify-between'>
                <p className='text-14 text-lightBlue whitespace-nowrap'>Projected Cost</p>
                <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                <p className='text-14 line-clamp-1 max-w-[unset]'>{`$${formatPriceWithComma(userData.client?.projected_cost)}`}</p>
              </div>
              <div className='flex justify-between'>
                <p className='text-14 text-lightBlue whitespace-nowrap'>Final Actual Cost</p>
                <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                <p className='text-14 line-clamp-1 max-w-[unset]'>{`$${formatPriceWithComma(userData.client?.scolarship.start - userData.client?.recovery_fund)}`}</p>
              </div>
              <div className='flex justify-between'>
                <p className='text-14 text-lightBlue whitespace-nowrap'>Recovered Funding</p>
                <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                <p className='text-14 line-clamp-1 max-w-[unset]'>{`$${formatPriceWithComma(userData.client?.recovery_fund)}`}</p>
              </div>
            </div>
            {(userData.client?.status === CLIENT_STATUS.failed || userData.client?.status === CLIENT_STATUS.in_progress) && <button
              onClick={() => setIsTerminateOpen(true)}
              className={classNames(
                'px-6 py-2.5 bg-transparent border border-red rounded-[10px] mt-6 self-center text-red text-16 font-medium duration-150 hover:border-orange hover:text-orange',
                'disabled:!border-darkGrey disabled:!text-darkGrey'
              )}
              type='button'
              disabled={userData.client?.status === CLIENT_STATUS.failed}
            >
              Terminate
            </button>}
            {isTerminateOpen &&
              <TerminateModal
                isOpen={isTerminateOpen}
                userDetails={userData}
                onClose={() => setIsTerminateOpen(false)}
                onSuccess={updateUserData}
              />}
            {userData.client?.tasks.length > 0 && <div className='flex flex-col mt-6 pt-6 w-full'>
              <p className='text-14 mb-3'>Forms</p>
              <div className='flex flex-col gap-y-4'>
                {userData.client?.tasks.map(task => {
                  return (
                    <div className='flex items-center p-4 rounded-lg shadow-block-shadow' key={task.task_id}>
                      <IconClipboardType className='mr-1 min-w-8 min-h-8' />
                      <p className='text-16 ml-1 mr-3 flex-1'>{task.name} - Week {task.week}</p>
                      <p className={`text-14 mr-6 ${task.completed ? 'text-green' : 'text-red'}`}>{task.completed ? 'Completed' : 'Not Completed'}</p>
                      <SmallButton
                        icon={
                          <IconEye
                            className={!task.completed ? 'pointer-events-none hover:text-darkGrey' : ''}
                            color={!task.completed ? COLORS.darkGrey : COLORS.blue}
                          />}
                        label='View'
                        href={`${userData.profile.user_id}/weekly-forms/${task.week}`}
                        disabled={!task.completed} />
                    </div>
                  )
                })}
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

