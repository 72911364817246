import React, { useEffect, useRef, useState } from 'react';
import { getRequestData } from 'api/api';
import { endpoints } from 'api/endpoints';
import { FinancesSinglePartner } from '../customTable/financesSinglePartner';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Button } from 'antd';
import { IconSmallArrow } from 'components';

export const FinancesPartners = ({ domain }) => {
  const [partners, setPartners] = useState([]);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useEffect(() => {
    (async () => {
      const { data } = await getRequestData(endpoints.getFinancesPartners, domain);
      if (data) {
        setPartners(data);
      }
    })();
  }, [domain]);

  return partners.length > 0 && (
    <div className='flex w-full mb-6 items-center relative'>
      <Button ref={prevRef} className='!w-6 !h-6 z-20 rounded-none rounded-l-lg border-0 absolute shadow-block-shadow bottom-0 left-0' icon={<IconSmallArrow className='w-1.5'/>} />
      <Button ref={nextRef} className='!w-6 !h-6 z-20 rounded-none rounded-r-lg border-0 absolute shadow-block-shadow bottom-0 right-0' icon={<IconSmallArrow className='rotate-180 w-1.5' />} />
      <Swiper
        className='!pt-6 !pb-8 w-full'
        spaceBetween={16}
        slidesPerView='auto'
        scrollbar={{
          hide: false,
          draggable: true,
          horizontalClass: '!bg-white',
          dragClass: 'swiper-scrollbar-drag !bg-divider !rounded-sm !h-4 !top-1',
        }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        modules={[Scrollbar, Navigation]}
      >
        {partners.map((partner, index) => (
          <SwiperSlide className='!w-[auto] h-full' key={`${partner.name}-${index}`}>
            <FinancesSinglePartner {...partner} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
