import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { IconSmallArrow, IconStar, OrangeButton, SmallButton } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROLE_TYPES } from 'api/constants';
import { endpoints } from 'api/endpoints';
import { clientSubmitFeedback, getRequestData } from 'api/api';
import { Form, Radio, Input, Rate } from 'antd';
import { getIdFromPath } from 'utils/helpers';

export const WeeklyRegularForm = ({ clientTasks, week }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [taskData, setTaskData] = useState();
  const [form] = Form.useForm();

  const weekId = useMemo(() => {
    return getIdFromPath(location.pathname)
  }, [location.pathname]);

  useEffect(() => {
    weekId && (async () => {
      const { data } = await getRequestData(endpoints.getClientFormsByWeek(weekId), ROLE_TYPES.client);
      if (data) setTaskData(data);

    })();

  }, [weekId]);

  const isWeeklyTaskCompleted = taskData && taskData[0]?.completed;

  const storeFields = useMemo(() => {
    return taskData && taskData[0]?.forms[0]?.feedback.map((form, index) => {
      return (
        <Fragment key={index}>
          <div className='flex flex-col px-6 py-4 bg-white rounded-block-radius-sm gap-y-4'>
            <div className='flex items-center gap-x-2'>
              <b className='nunito text-orange italic'>{index + 1}.</b>
              <p className='font-medium text-16'>{form.question}</p>
            </div>
            <Form.Item
              name={form.feedback_id}
              initialValue={form.type === 'radio' ? form.items[0].feedback_item_id : undefined}
              rules={[{ required: form.mandatory, message: 'This field is required' }]}
            >
              {form.type === 'rating' ?
                <Rate
                  disabled={isWeeklyTaskCompleted}
                  name={`input-${form.feedback_id}`}
                  character={({ index = 0 }) => {
                    return (
                      <div className='rate flex flex-col items-center rounded-xl border border-color-divider py-2.5 bg-white w-[78px] h-[78px] gap-y-2.5'>
                        <IconStar />
                        <span className='text-16 text-black rate-text'>{index + 1}</span>
                      </div>
                    )
                  }}
                /> : form.type === 'radio' ?
                  (
                    <Radio.Group name={`input-${form.feedback_id}`}>
                      {form.items.map((item) => {
                        return (
                          <Radio
                            disabled={isWeeklyTaskCompleted}
                            key={`radio-${item.feedback_item_id}`}
                            className='px-4 py-2.5 bg-white border border-color-divider rounded-xl mr-0'
                            value={item.feedback_item_id}>
                            {item.feedback_item_label}
                          </Radio>
                        )
                      })}
                    </Radio.Group>
                  )
                  : (
                    <Input.TextArea
                      readOnly={isWeeklyTaskCompleted}
                      className='!resize-none font-poppins text-14 p-3 pl-4 border border-color-divider rounded-ten max-h-[100px]'
                      autoSize={false}
                      name={`input-${form.feedback_id}`}
                      rows={4}
                      placeholder="Placeholder"
                    />
                  )
              }
            </Form.Item>
          </div>
        </Fragment>
      )
    })
  }, [isWeeklyTaskCompleted, taskData])

  const initialValues = useMemo(() => {
    if (!taskData) return {};

    const feedbackObject = taskData[0]?.forms[0].feedback.reduce((acc, item) => {
      if (item.items.length > 1) {
        const selectedItem = item.items.find(feedbackItem => feedbackItem.answer === String(feedbackItem.feedback_item_id) || feedbackItem.answer === String(feedbackItem.feedback_item_label));
        acc[item.feedback_id] = selectedItem && item.type === 'rating' ? selectedItem.answer : selectedItem?.feedback_item_id;
      } else {
        acc[item.feedback_id] = item.items[0].answer;
      }

      return acc;
    }, {});

    return feedbackObject;
  }, [taskData]);

  const handleFormSubmit = (values) => {
    const data = Object.entries(values).map(([key, value]) => {
      const questionData = taskData[0]?.forms[0].feedback.find(f => f.feedback_id.toString() === key);
      let selectedItem;

      if (questionData.items.length > 1) {
        selectedItem = questionData.items.find(item =>
          (item.feedback_item_id.toString() === item?.answer || item.feedback_item_id.toString() === value.toString()) ||
          (item?.feedback_item_label.toString() === item?.answer || item?.feedback_item_label.toString() === value.toString()))
      } else {
        selectedItem = questionData.items[0];
      }

      return {
        form_id: taskData[0].forms[0].form_id,
        feedback_id: Number(key),
        feedback_item_id: selectedItem.feedback_item_id,
        task_id: taskData[0].task_id,
        answer: value.toString(),
      };
    });

    clientSubmitFeedback(data, ROLE_TYPES.client, () => {
      navigate('/client/dashboard')
    });
  };

  return taskData && (
    <div className='flex flex-col flex-1 gap-y-8'>
      <div className='flex justify-between'>
        <div className='flex items-center gap-x-4'>
          <div onClick={() => navigate(-1)} className='min-w-10 h-10 bg-white rounded-lg shadow-block-shadow flex items-center justify-center cursor-pointer'>
            <IconSmallArrow className='w-2 h-4' />
          </div>
          <p className='nunito-title'>{taskData[0]?.forms[0].name}</p>
        </div>
      </div>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleFormSubmit}
        className='flex flex-col gap-y-4 client-forms'>
        {storeFields}
        {!isWeeklyTaskCompleted && <div className='flex self-end gap-x-4 mt-2'>
          <SmallButton label='Cancel' href='/client/dashboard' btnClassName='!h-[44px]' />
          <OrangeButton type='submit' label='Send' />
        </div>}
      </Form>
    </div>
  );
};