import { Tooltip } from "antd";
import { IconBed } from "components";
import { IconPatient } from "components/icons/Patient";
import { memo as Memo } from "react";
import { COLORS } from "utils/colors";

export const SingleBed = ({ isBedOccupied, toggleBed, inEdit, isPWClient }) => {
  const tooltipBody = () => {
    if (!inEdit) return '';

    return (
      <div className="p-4 bg-white rounded-xl shadow-block-shadow">
        <div className="flex gap-x-1 px-2 py-1 border border-divider rounded-md">
          <IconBed color={isBedOccupied ? COLORS.red : COLORS.green} />
          <p className="text-14 text-black">Mark as {isBedOccupied ? 'Available' : 'Occupied'}</p>
        </div>
      </div>
    );
  }

  return (
    <Tooltip
      destroyTooltipOnHide
      title={tooltipBody()}
      color="transparent"
      overlayInnerStyle={{ padding: '0', borderRadius: '0', backgroundColor: "transparent", boxShadow: 'none' }}
    >
      <div onClick={inEdit ? toggleBed : undefined} className={`relative ${isBedOccupied ? 'bg-pink' : 'bg-lightGreen'} ${inEdit ? 'hover:border-2 hover:border-blue cursor-pointer' : ''} rounded-full w-10 h-10 flex items-center  justify-center`}>
        {isPWClient &&
          <IconPatient color={COLORS.orange} className='absolute top-0 right-0 translate-x-1/3 -translate-y-1/4 p-1 w-5 h-5 rounded-full bg-white' />
        }
        <IconBed color={isBedOccupied ? COLORS.red : COLORS.green} className='pointer-events-none' />
      </div>
    </Tooltip>
  );
}

export default Memo(SingleBed);
